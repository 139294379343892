import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CedentSideBar from './includes/CedentSideBar';
import { cedentNavMenuList } from '../../configs/sideMenuItem';
import clsx from 'clsx';
import { FormatAlignLeft as IconFormatAlignLeft, } from '@mui/icons-material';
import CedentNavbar from './includes/CedentNavbar';

export default function CedentNewLayout() {
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(false);
    const [_message, _setMessage] = useState<any>([]);
    const [_proposalRequestResponseId, _setResponseId] = useState<any>();
    const [_request, _setRequest] = useState([]);
    const [_response, _setResponse] = useState([]);
    const [_company, _setCompany] = useState('');
    const [_category, _setCategory] = useState('');
    const [_proposalNo, _setproposalNo] = useState('');
    const [_proposalId, _setProposalId] = useState<any>();
    const [_totalCount, _setTotalCount] = useState<any>();

    const toggleSidebarClick = () => {
        _setToggleSidebar(!_toggleSidebar);
    }

    useEffect(() => {
        _setToggleSidebar(!isDesktop);
    }, [isDesktop])


    return (<>
        <Suspense fallback={null}>
            <div className="min-vh-100">
                <div>
                    <CedentSideBar navMenuList={cedentNavMenuList} toggleSidebar={_toggleSidebar} />
                </div>
                <div>
                    <div className={clsx("navHeader", _toggleSidebar && 'navHeaderFull', 'd-flex justify-content-between align-items-center bg-white px-3')}>
                        <IconButton onClick={toggleSidebarClick}><IconFormatAlignLeft /></IconButton>
                        <div className='d-flex'>
                            <CedentNavbar />
                        </div>
                    </div>
                    <main className={clsx('contentPage', _toggleSidebar && 'contentPageFull', 'bg-main-light px-3')}>
                        <Outlet />
                    </main>
                </div>
            </div>
        </Suspense>
    </>)
}

const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
}
