
import { paths } from './constants';
import AuditWhite from '../assets/images/sidebar/AuditWhite.png';
import ConfigWhite from '../assets/images/sidebar/ConfigWhite.png';
import Assignment from '../assets/images/sidebar/Assignment.png';
import { HomeOutlined } from "@mui/icons-material";
import { CreateProposalSvg, DashboardSvg, HistorySvg, MailsSvg, ProposalListingSvg } from '../assets/images/svg/exportSvg';

interface propsMenuItem {
    name: string;
    to: string;
}

export interface propsMenuList {
    name: string;
    to: string;
    icon?: any;
    sub?: propsMenuItem[];
}

export const adminNavMenuList: propsMenuList[] = [
    {
        name: 'Dashboard',
        to: paths.admin.Dashboard,
        icon: HomeOutlined,
        sub: [
            {
                name: 'Proposal Dashboard',
                to: paths.admin.proposalDashboard
            },
            {
                name: 'Proposal Listing',
                to: paths.admin.proposalListing
            },
            // {
            //     name: 'Performance Reports',
            //     to: paths.admin.PerformanceReport
            // },
            {
                name: 'Proposal Pending',
                to: paths.admin.PendingProposal
            },
            {
                name: 'Cedent Business Reports',
                to: paths.admin.CedentReport
            },
            {
                name: 'Re-Insurer Business Reports',
                to: paths.admin.ReInsurerReport
            }
            ,
            {
                name: 'Mails',
                to: paths.admin.adminMails
            }
        ]
    },
    // {
    //     name: 'Proposal Details',
    //     to: paths.admin.proposalDetails,
    //     icon: ResponseWhite
    // },
    {
        name: 'Config',
        to: paths.admin.Config,
        icon: ConfigWhite,
        sub: [
            {
                name: 'Cedant Maintenance',
                to: paths.admin.cendetMaintainence
            },
            {
                name: 'Risk Classification',
                to: paths.admin.riskClassification
            },
            {
                name: 'Reinsurer Maintenance',
                to: paths.admin.reinsuranceMaintainence
            },
            {
                name: 'Country Maintenance',
                to: paths.admin.countryMaintainence
            },
            {
                name: 'Proposal Type',
                to: paths.admin.operatingIndustries
            },
            {
                name: 'Reject Reasons',
                to: paths.admin.rejectReasons
            },
            {
                name: 'Occupancy Master',
                to: paths.admin.occupancy
            },
            {
                name: 'Access Menu',
                to: paths.admin.accessMenu
            },
            {
                name: 'Branches',
                to: paths.admin.bharatRebranches
            },
            {
                name: 'User Classification',
                to: paths.admin.userClassification
            },
            {
                name: 'Add On Coverage',
                to: paths.admin.addOncoverage
            },
            {
                name: 'Under Writing Clause',
                to: paths.admin.underWritingclass
            },
            // {
            //     name: 'Special Clauses',
            //     to: paths.admin.specialClauses
            // },
            {
                name: 'User Details',
                to: paths.admin.userDetails
            },
            {
                name: 'Executive Assign',
                to: paths.admin.executiveAssign
            },
            {
                name: 'Parameter Configuration',
                to: paths.admin.financialyear
            },

        ]
    },
    {
        name: 'System Log',
        to: paths.admin.AuditLog,
        icon: AuditWhite
    },
    {
        name: 'Executive Re-Assign',
        to: paths.admin.reAssignExecutive,
        icon: Assignment
    },
    // {
    //     name: 'Proposal Upload',
    //     to: paths.admin.proposalUpload,
    //     icon: CloudUpload
    // },
];

export const cedentNavMenuList: propsMenuList[] = [
    {
        name: 'Dashboard',
        to: paths.cedent.Dashboard,
        icon: DashboardSvg,
    },
    {
        name: 'Proposal Listings',
        to: paths.cedent.ProposalListings,
        icon: ProposalListingSvg,
    },
    {
        name: 'History',
        to: paths.cedent.History,
        icon: HistorySvg,
    },
    {
        name: 'Create New Proposal',
        to: paths.cedent.CreateNewPost1,
        icon: CreateProposalSvg,
    },
    {
        name: 'Mails',
        to: paths.cedent.MailPage,
        icon: MailsSvg,
    },

]

export const executiveNavMenuList: propsMenuList[] = [
    {
        name: 'Dashboard',
        to: paths.executive.Dashboard,
        icon: DashboardSvg,
    },
    {
        name: 'Proposal Listings',
        to: paths.executive.ProposalListing,
        icon: ProposalListingSvg,
    },
    {
        name: 'History',
        to: paths.executive.History,
        icon: HistorySvg,
    },
]

export const reinsuranceNavMenuList: propsMenuList[] = [
    {
        name: 'Dashboard',
        to: paths.reinsurance.Dashboard,
        icon: DashboardSvg,
    },
    {
        name: 'Proposal Listings',
        to: paths.reinsurance.ProposalListing,
        icon: ProposalListingSvg,
    },
    {
        name: 'History',
        to: paths.reinsurance.history,
        icon: HistorySvg,
    },
]