import React from 'react'
import Facebook from '../../../assets/images/Cedent/Facebook.png'
import twitter from '../../../assets/images/Cedent/twitter.png'
import googleplus from '../../../assets/images/Cedent/googleplus.png'
import mail from '../../../assets/images/Cedent/mail.png'
import map from '../../../assets/images/Cedent/map.png'
import phone from '../../../assets/images/Cedent/phone.png'

function Footer() {
    return (
        <div className='container-fluid'>
            <div className='row justify-content-evenly pt-5 bg-light'>
                <div className='col-md-3 text-start'>
                    <p className='fw-bold my-2 text-primary'>Company Logo</p>
                    <p className=' text-start text-muted'><small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis iure adipisci quidem qui blanditiis eveniet necessitatibus deleniti impedit quod delectus.</small></p>
                    <div className=''>
                        <img src={Facebook} alt="" className='footer-social-icon' />
                        <img src={twitter} alt="" className='footer-social-icon mx-3' />
                        <img src={googleplus} alt="" className='footer-social-icon' />
                    </div>
                </div>
                <div className='col-md-3 text-start'>
                    <ul className='list-unstyled bolder'>
                        <li className='mt-1 fw-bold'>Usefull Links</li>
                        <li className='mt-3'><small>Aboutus</small></li>
                        <li className='mt-1'><small>Contactus</small></li>
                        <li className='mt-1'><small>Become a Client</small></li>
                        <li className='mt-1'><small>Technical Support</small></li>
                    </ul>
                </div>
                <div className='col-md-3 justify-content-center'>
                    <ul className='list-unstyled text-start bolder'>
                       <li className='my-1 fw-bold'>Contact Info</li>
                        <li className='mt-3'><img src={mail} alt="" className='me-2 footer-contact'/><small>example@yahoo.com</small></li>
                        <li className='mt-3'><img src={phone} alt="" className='me-2 footer-contact'/><small>+0 320 422 4254</small></li>
                        <li className='mt-3'><img src={map} alt="" className='me-3 footer-contact'/><small>Lorem Ipsum</small></li>
                    </ul>
                </div>
                <p className='text-center mt-4 bolder'><small>Copyright &#169;2022 All rights reserved</small></p>
            </div>
        </div>

    )
}
export default Footer;
