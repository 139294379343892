import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './includes/Footer';
import AppNavBar from './includes/AppNavBar';



function AppLayout() {
    return (<>
        <div className="min-vh-100">
            <AppNavBar />
            <div className='back-Img-App p-5'>
                <div className="row align-items-center justify-content-center ">
                    <div className='col-md-10'>
                        <Outlet />
                    </div>
                </div>
            </div>
            <div className=''><Footer /></div>
        </div>
    </>);
}

export default AppLayout;