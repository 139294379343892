const base = '/';

let apiBaseAdmin        = "https://api.reins-global.com/api/admin/";
let apiBaseExecutive    = "https://api.reins-global.com/api/executive/";
let apiBase             = "https://api.reins-global.com/api/registration/";
let apiBaseFileUpload   = "https://api.reins-global.com/api/DocumentUpload/";
if (process.env.NODE_ENV === "production") {
    apiBaseAdmin        = "https://api.reins-global.com/api/admin/";
    apiBaseExecutive    = "https://api.reins-global.com/api/executive/";
    apiBase             = "https://api.reins-global.com/api/registration/";
    apiBaseFileUpload   = "https://api.reins-global.com/api/DocumentUpload/";
}

export const paths = {
    home                                    : base,
    auth:{
        login                               : base + 'login'
    },
    accounts: {
        home                                : base + 'accounts',
        homePageLogin                       : base + 'accounts/home-page-login',
        cedentLogin                         : base + 'accounts/cedent-login',
        sentRequest                         : base + 'accounts/sent-request',
        forgotPassword                      : base + 'accounts/forgot-password',
        reinsuranceLogin                    : base + 'accounts/reinsurance-login',
        adminLogin                          : base + 'accounts/admin-login',
        executiveLogin                      : base + 'accounts/executive-login',
        logout                              : base + 'accounts/logout',
    },


    cedent: {
        home                                : base + 'cedent',
        Dashboard                           : base + 'cedent/dashboard',
        ProposalListings                    : base + 'cedent/proposal-listings',
        History                             : base + 'cedent/history',
        Settings                            : base + 'cedent/settings',
        EditProfile                         : base + 'cedent/edit-profile',
        MailPage                            : base + 'cedent/mail',
        CreateNewPost1                      : base + 'cedent/create-newPost1',
        CreateNewPost2                      : base + 'cedent/create-newPost2',
        CreateNewPost2_5                    : base + 'cedent/create-newPost2_5',
        CreateNewPost3_0                    : base + 'cedent/create-newPost3_0',
        CreateNewPost3                      : base + 'cedent/create-newPost3',
        CreateNewPost3_5                    : base + 'cedent/create-newPost3_5',
        CreateNewPost4                      : base + 'cedent/create-newPost4',
        CreateNewPost4_0                    : base + 'cedent/create-newPost4_0',
        Notification                        : base + 'cedent/Notifications',

        viewPost1                           : base + 'cedent/view-post1',
        viewPost2                           : base + 'cedent/view-post2',
        viewPost2_5                         : base + 'cedent/view-post2_5',
        viewPost3_0                         : base + 'cedent/view-post3_0',
        viewPost3                           : base + 'cedent/view-post3',
        viewPost3_5                         : base + 'cedent/view-post3_5',
        viewPost4                           : base + 'cedent/view-post4',
        viewPost4_0                         : base + 'cedent/view-post4_0',
    },

    reinsurance: {
        home                                : base + 'reinsurance',
        Dashboard                           : base + 'reinsurance/dashboard',
        ProposalListing                     : base + 'reinsurance/proposal-listing',
        // ViewPolicyPost                      : base + 'reinsurance/view-policyPost',
        // viewProposal1                       : base + 'reinsurance/view-proposal1',
        // viewProposal2                       : base + 'reinsurance/view-proposal2',
        // viewProposal3                       : base + 'reinsurance/view-proposal3',
        MyApplication                       : base + 'reinsurance/my-application',
        history                             : base + 'reinsurance/history',
        Settings                            : base + 'reinsurance/settings',
        EditProfile                         : base + 'reinsurance/edit-profile',
        ReInsurenceHistory                  : base + 'reinsurance/history',

        viewPost1                           : base + 'reinsurance/view-post1',
        viewPost2                           : base + 'reinsurance/view-post2',
        viewPost2_5                         : base + 'reinsurance/view-post2_5',
        viewPost3_0                         : base + 'reinsurance/view-post3_0',
        viewPost3                           : base + 'reinsurance/view-post3',
        viewPost3_5                         : base + 'reinsurance/view-post3_5',
        viewPost4                           : base + 'reinsurance/view-post4',
        viewPost4_0                         : base + 'reinsurance/view-post4_0',
    },


    admin: {
        home                                : base + 'admin',
        Dashboard                           : base + 'admin/dashboard',
        proposalDashboard                   : base + 'admin/dashboard/proposal-dashboard',
        proposalListing                     : base + 'admin/dashboard/proposal-listing',
        ProposalListing                     : base + 'admin/dashboard/proposal-listing',
        Plan                                : base + 'admin/plan',
        Subscribers                         : base + 'admin/subscribers',
        proposalDetails                     : base + 'admin/proposal-details',
        viewProposal1                       : base + 'admin/view-proposal-1',
        viewProposal2                       : base + 'admin/view-proposal-2',
        viewProposal3                       : base + 'admin/view-proposal-3',
        viewProposal4                       : base + 'admin/view-proposal-4',
        Config                              : base + 'admin/config',
        cendetMaintainence                  : base + 'admin/config/cendet-maintainence',
        reinsuranceMaintainence             : base + 'admin/config/reinsurance-maintainence',
        financialyear                       :base +  'admin/config/financialyear',
        countryMaintainence                 : base + 'admin/config/country-maintainence',
        operatingIndustries                 : base + 'admin/config/proposal-type',
        rejectReasons                       : base + 'admin/config/reject-reasons',
        occupancy                           : base + 'admin/config/occupancy',
        accessMenu                          : base + 'admin/config/access-menu',
        bharatRebranches                    : base + 'admin/config/bharat-re-branches',
        riskClassification                  : base + 'admin/config/risk-classification',
        userClassification                  : base + 'admin/config/user-classification',
        addOncoverage                       : base + 'admin/config/addon-coverage',
        underWritingclass                   : base + 'admin/config/underwriting-class',
        specialClauses                      : base + 'admin/config/special-clauses',
        userDetails                         : base + 'admin/config/user-details',
        AuditLog                            : base + 'admin/audit-log',
        PendingProposal                     : base + 'admin/dashboard/pending-proposal',
        CedentReport                        : base + 'admin/dashboard/cedent-report' ,
        ReInsurerReport                     : base + 'admin/dashboard/reinsurer-report',
        PerformanceReport                   : base + 'admin/dashboard/performance-report',
        executiveAssign                     : base + 'admin/config/executiveMaintainence',
        reAssignExecutive                   : base + 'admin/re-assign-executive',
        proposalUpload                      : base + 'admin/proposal-upload',
        adminMails                          : base + 'admin/dashboard/admin-mails',

        viewPost1                           : base + 'admin/view-post1',
        viewPost2                           : base + 'admin/view-post2',
        viewPost2_5                         : base + 'admin/view-post2_5',
        viewPost3_0                         : base + 'admin/view-post3_0',
        viewPost3                           : base + 'admin/view-post3',
        viewPost3_5                         : base + 'admin/view-post3_5',
        viewPost4                           : base + 'admin/view-post4',
        viewPost4_0                         : base + 'admin/view-post4_0',
        
    },


    executive: {
        home                                : base + 'executive',
        Dashboard                           : base + 'executive/dashboard',
        ProposalListing                     : base + 'executive/proposal-listing',
        // proposalListing1                    : base + 'executive-Proposal-listing1',
        // proposalListing2                    : base + 'executive-Proposal-listing2',
        // proposalListing3                    : base + 'executive-Proposal-listing3',
        // proposalListing4                    : base + 'executive-Proposal-listing4',
        reinsurance                         : base + 'executive/reinsurance',
        Submission                          : base + 'executive/reinsurance/submission',
        History                             : base + 'executive/history',
        
        viewPost1                           : base + 'executive/view-post1',
        viewPost2                           : base + 'executive/view-post2',
        viewPost2_5                         : base + 'executive/view-post2_5',
        viewPost3_0                         : base + 'executive/view-post3_0',
        viewPost3                           : base + 'executive/view-post3',
        viewPost3_5                         : base + 'executive/view-post3_5',
        viewPost4                           : base + 'executive/view-post4',
        viewPost4_0                         : base + 'executive/view-post4_0',
    },


    becomeClient                            : base + 'become-client',
    basicInfo                               : base + 'basic-info',

// API's
    api: {
        // Admin API's
        admin:{
            allLogin                        : apiBaseAdmin + 'getuserlogin',
            updatePassword                  : apiBaseAdmin + 'updatepassword',
            getCompanyRole                  : apiBaseAdmin + 'getmastercompanyrole',
            getCompanyRoleById              : apiBaseAdmin + 'getmastercompanyrolebyid',
            insertCompanyRole               : apiBaseAdmin + 'insertmastercompanyrole',
            updateCompanyRole               : apiBaseAdmin + 'updatecompanyrole',
            getAllCountry                   : apiBaseAdmin + 'getallcountary',
            insertCountry                   : apiBase + 'insertcountary',
            updateCountry                   : apiBaseAdmin + 'updatecountary',
            getOccupancy                    : apiBaseAdmin + 'getOccupancies',
            getOccupancyById                : apiBaseAdmin + 'getOccupanciesbyid',
            insertOccupancy                 : apiBaseAdmin + 'insertOccupancies',
            updateOccupancy                 : apiBaseAdmin + 'updateOccupancies',
            getIndustry                     : apiBaseAdmin + 'getproposaltype',
            getIndustryById                 : apiBaseAdmin + 'getproposaltypebyid',
            insertIndustry                  : apiBaseAdmin + 'insertproposaltype',
            updateIndustry                  : apiBaseAdmin + 'updateproposaltype',
            getProposalType                 : apiBaseAdmin + 'getproposaltype',
            getProposalTypeById             : apiBaseAdmin + 'getproposaltypebyid',
            insertProposalType              : apiBaseAdmin + 'insertproposaltype',
            updateProposalType              : apiBaseAdmin + 'updateproposaltype',
            getRiskClassification           : apiBaseAdmin + 'getallActiveRisk',
            getAllRiskClassification        : apiBaseAdmin + 'getallrisk',
            insertRiskClassification        : apiBaseAdmin + 'insertriskclassification',
            updateRiskClassification        : apiBaseAdmin + 'updateriskclassification',
            getUserClassification           : apiBaseAdmin + 'getalluserclassification',
            insertUserClassification        : apiBaseAdmin + 'insertuserclassification',
            updateUserClassification        : apiBaseAdmin + 'updateuserclassification',
            getAllReBranch                  : apiBaseAdmin + 'getallbranch',
            insertReBranch                  : apiBaseAdmin + 'insertbranch',
            updateReBranch                  : apiBaseAdmin + 'updatebranch',
            getCedentMaintainence           : apiBaseAdmin + 'getcedentmaintenance',
            insertCedentMaintainence        : apiBaseAdmin + 'insertcedentmaintance',
            updateCedentMaintainence        : apiBaseAdmin + 'updatemastercedentmaintenance',
            getReinsuranceMaintainence      : apiBaseAdmin + 'getmasterreinsurancemaintenance',
            insertReinsuranceMaintainence   : apiBaseAdmin + 'insertreinsurancemaintance',
            updateReinsuranceMaintainence   : apiBaseAdmin + 'updatemasterreinsurancemaintenance',
            deleteReinsuranceMaintainence   : apiBaseAdmin + 'deleteReinsurer',
            getMailList                     : apiBaseAdmin + 'getemaillist',
            getRejectReason                 : apiBaseAdmin + 'getallrejectreason',
            insertRejectReason              : apiBaseAdmin + 'insertrejectreason',
            updateRejectReason              : apiBaseAdmin + 'updaterejectreason',
            getUserDetails                  : apiBaseAdmin + 'getalluserdetails',
            insertUserDetails               : apiBaseAdmin + 'insertuserDetails',
            updateUserDetails               : apiBaseAdmin + 'updateuserdetails',
            getUserRole                     : apiBaseAdmin + 'getallrole',
            getUserRoleById                 : apiBaseAdmin + 'getallrolebyid',
            insertUserRole                  : apiBaseAdmin + 'insertuserrole',
            updateUserRole                  : apiBaseAdmin + 'updateuserrole',
            getAuditLog                     : apiBaseAdmin + 'getauditlog',
            insertAuditLog                  : apiBaseAdmin + 'insertauditlog',
            getAllUserAccess                : apiBaseAdmin + 'getalluseraccess',
            getUserAccessById               : apiBaseAdmin + 'getalluseraccessbyid',
            insertUserAccess                : apiBaseAdmin + 'insertuseraccess',
            updateUserAccess                : apiBaseAdmin + 'updateuseraccess',
            getCedentLastId                 : apiBaseAdmin + 'getcendentmaitanance-lastid',
            cedentDetail                    : apiBaseAdmin + 'getcedantdetails',
            getReInsBusinessReport          : apiBaseAdmin + 'Reinsurance_BusinessReportDashBoard',
            getFilterAdminProposal          : apiBase + 'Performance_reportFilterDashBoard',
            getFilterPendingProposal        : apiBaseAdmin + 'ProposalPendingFilterDashBoard',
            getFilterCedentBusiness         : apiBase + 'CedentFilter',
            getFilterReinsureProposal       : apiBase + 'ReinsurerBusinessReportFilterDashBoard',
            getCedentReport                 : apiBaseAdmin + 'Cedent_Business_report',
            getAdminProposalLists           : apiBaseAdmin + 'Performance_reportDashBoard',
            getPendingProposal              : apiBaseAdmin + 'Pending_proposals',
            getReInsuranceFilterTable       : apiBase + 'Reinsurer_BusinessCountfilter',
            getPerformanceFilterTable       : apiBase + 'Performance_ReportCountfilter',
            executiveAssignCreateClick      : apiBaseAdmin + 'ExecutiveAssigntoCedent',
            getExecutiveAssignTable         : apiBaseAdmin + 'GetCedentandExecutive',
            getcedentProposalListByDashBoard : apiBase + 'Cedant_Business_Report_Countfilter',
            uploadProposalByExcel            : apiBaseAdmin +'excel-file-upload-for-proposal',
            getExcelCount                    : apiBaseAdmin + 'getexcelcount',
            proposalTypeList                 : apiBaseAdmin + 'getallproposalType',
            reinsureUpdateMultiEmail         : apiBaseAdmin + 'updateemail',
            reinsureInsertMultiEmail         : apiBaseAdmin + 'insertemail',
            reinsureGetMultiEmail            : apiBaseAdmin + 'getemaillist',
            getparameterconfig               :apiBaseAdmin + 'getparameterconfig',
            updateparameterconfiguration     :apiBaseAdmin +'updateparameterconfiguration',
            getCedentConfig                  :apiBaseAdmin +'getcedentdetails',
            updateproposalno                 :apiBaseAdmin +'updateproposalno',
            deleteEmailCedentMain            :apiBaseAdmin +'deleteemailced',
            getExecutiveProposalListing      :apiBaseAdmin +'allExeProposaListing',
            insertaddoncover                 :apiBaseAdmin +'InsertOrUpdateAddOnCover',
            adminLogout                      :apiBaseAdmin +'Logout',
            GetAddOnCover                    :apiBaseAdmin +'GetMasterAddOnCover',
            GetAddOnCoverByProposalType      :apiBaseAdmin +'GetAddOnCoverByProposalType',
            insertunderwriting               :apiBaseAdmin +'InsertOrupdateMasterUnderWritingClass',
            Getunderwriting                  :apiBaseAdmin +'GetMasterUnderWritingClass',
            GetUnderwritingByProposalType    :apiBaseAdmin +'GetUnderWritingClassByProposalType',
            getAllEmails                     :apiBase + 'getEmailInboxForAdmin',
            GetAllExeProposal                :apiBaseAdmin + 'GetAllExeProposaListing',
            DownloadPdfFileByURL             :apiBase + 'DownloadPdfFileByURL',
            SendTestMailSmtp                 :apiBase + 'sendTestmailSMTP',
        },

        // Reinsurance API's
        reinsurance:{
            reinsureDashboard               : apiBase + 'getreinsurancedeskboard',
            reinsureDashboardByStatusId     : apiBase + 'getreinsurancedeskboardByStatusId',
            getProposalList                 : apiBase + 'active-proposal-by-reinsurance-id',
            getHistoryProposal              : apiBase + 'getReinsurerHistory',
            sendAcceptMail                  : apiBase + 'sendAcceptMail',
            getAcceptProposal               : apiBase + 'accept-proposal-by-proposal-reinsurance-id',
            getRejectProposal               : apiBase + 'reject-proposal-by-proposal-reinsurance-id',
            getNotification                 : apiBase + 'reinsurancenotification',
            getProposalByUserId             : apiBase + 'getproposaldetailby-proposal-reinsurance-id',
            getProposalIdDetail             : apiBase + 'getproposaldetailsby-proposal-reinsurance-id',
            ReInsuranceCheck                : apiBaseExecutive + 'insertproposallistingcheck',
            restoreData                     : apiBase + 'restore-Reinsuranceproposal',
            archieveData                    : apiBase + 'archieve-Reinsuranceproposal',
            getReinsuranceNotification      : apiBase + 'reinsurancenotification',
            getReinsuranceMsg               : apiBase + 'getchatmsgforReinsurer',
            getExcelAllProposalListing      : apiBase + 'active-proposal-by-reinsurance-id-ExcelDownload',

        },

        // Cedent API's
        cedent:{
            cedentCountry                   : apiBase + 'country',
            cedentRole                      : apiBase + 'company-role',
            cedentProposalListing           : apiBase + 'active-proposal',
            cedentIndustries                : apiBase + 'industry',
            cedentHistory                   : apiBase + 'proposals',
            cedentRestore                   : apiBase + 'restore-proposal',
            cedentProposalByProposalId      : apiBase + 'proposal-by-proposal-id',
            cedentProposalType              : apiBase + 'proposal-type',
            cedentOccupancy                 : apiBase + 'occupancy',
            occupancyByRiskId               : apiBase + 'occupancybyriskid',
            cedentProposalRegister          : apiBase + 'proposal',
            cedentRequestResponse           : apiBase + 'proposal-request-response-by-proposal-id',
            cedentPostRequestresponse       : apiBase + 'proposal-request-response',
            cedentDocumentUpload            : apiBaseFileUpload + 'FileUpload/proposal',
            documentDownload                : apiBaseFileUpload + 'FileUpload/DownloadFile',
            cedentArchieve                  : apiBase + 'archieve-proposal',
            cedentDashBoard                 : apiBase + 'deshborad',
            cedentNotifications             : apiBase + 'cedentnotification',
            cedentNotificationRead          : apiBase + 'markread',
            cedentInsertChat                : apiBase + 'insertchatmessage',
            sendChatMail                    : apiBase + 'sendChatEmail',
            cedentGetChatMessage            : apiBase + 'getchatmsgforReinsurer',
            getLatestId                     : apiBase + 'GetLatestProposallist',
            getNotification                 : apiBase + 'executivenotification',
            getProposalType                 : apiBase + 'proposal/Getproposaltypes',
            getDomesticmarketemaillist      : apiBase + 'proposal/GetDomesticmarketemaillist',
            getDraftPopupList               : apiBase + 'getdraftproposalpopup',
            getDraftList                    : apiBase + 'getdraftproposal-by-user-id',
            getDraftListTwo                 : apiBase + 'getdraftproposal-by-user-id-part2',
            getDraftListThree                 : apiBase + 'getdraftproposal-by-user-id-part3',
            getEmailList                    : apiBase + 'getEmailInbox',
            editProposal                    : apiBase + 'proposal-by-proposal-id',
            sendProposalMail                : apiBase + 'sendproposalmailpdf',
            sendAcceptedMail                : apiBase + 'updateproposalacceptance',
            sendCedentAcceptedMail          : apiBase + 'sendcedentProposalAcceptance',
            getAcceptedProposal             : apiBase + 'getAcceptedResinurer',
            getRemainingReinsurer           : apiBase + 'getRemainingReinsurer',
            getAddoncover                   : apiBase + 'getAddOnCover',
            getUnderWritingClause           : apiBase + 'getUnderWriting',
            getAllCountryObligative         : apiBase + 'GetAllCountryObligative',
            getAllmarinefacilities          : apiBase + 'GetMarineFacilitiesList',
            GetLandBasedFacilities          : apiBase + 'GetMarineLandBasedFacilities',
            GetOtherService                 :  apiBase + 'GetMarineOtherService',
            getMarineAgreedWithAnyContractors:  apiBase + 'GetMarineAgreedWithAnyContractors',
            getMarineLiabilityPortThroughputRevenue:  apiBase + 'GetMarineLiabilityPortThroughputRevenue',
            getProposalPartTwo:  apiBase + 'proposal-by-proposal-id-part-two',
            getProposalPartThree:  apiBase + 'proposal-by-proposal-id-part-three',

        },

        // Executive API's
        executive:{

            copyProposal                    : apiBaseExecutive + 'newExectuproposal',
            executiveRegister               : apiBaseExecutive + 'Insertexcutivelogin',
            propsalList                     : apiBaseExecutive + 'getproposallis',
            propsalListById                 : apiBaseExecutive + 'getproposallisbyid',
            getAllProposals                 : apiBase + 'Executive/GetProposallisting',
            insertRiSlip                    : apiBaseExecutive + 'Rislipcu',
            riSlipMail                      : apiBaseExecutive + 'rislipMailFromReins',
            getRiSlip                       : apiBaseExecutive + 'getRiSlipList',
            getExecutiveDropDown            : apiBaseExecutive + 'GetExecutives',
            getReinsuranceDropDown          : apiBaseExecutive + 'GetReinsurer',
            ExecutiveHitory                 : apiBase + 'Executive/GetProposallistingbyarchieve',
            ExecutiveAsign                  : apiBaseExecutive + 'AssignExecutive',
            ExecutiveGetReinsuranceData     : apiBase + 'Executive/getExecutiveProposalListbyListID',
            ExecutiveDashBoard              : apiBaseExecutive + 'getdashboardcount',
            getMsgFromReInsure              : apiBase + 'getchatmsgforReinsurer',
            getMsgFromCedent                : apiBase + 'getchatmsgforReinsurer',
            dashboardClickProposal          : apiBase + 'executive/getexecutivelists',
            dashboardRiSlipClick            : apiBaseExecutive + 'Pending_riSlip',
            dashboardCedentReInsClick       : apiBaseExecutive + 'getResponse_Received',
            dashboardPartialAccept          : apiBaseExecutive + 'PartialAcceptance',
            getcedentByExecutiveId          : apiBaseExecutive + 'cedentdetailsbyexecutiveid',
            sendCoverbindingMail            : apiBaseExecutive + 'sendCoverbindingMail',
            getCedentDashboardCount         : apiBaseExecutive + 'getdashboardcountByStatusId',
        }
    }
}
