import React, { useEffect, useState } from 'react';
import { httpRequest } from '../services/httpService';
import { useNavigate } from 'react-router-dom';
import { paths } from '../configs/constants';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useStateValue } from './stateProvider';
import { authService } from '../services/authService';

export function AxiosProvider() {
    const navigate = useNavigate();
    const [{ user }, dispatch]: any = useStateValue();
    const [open, setOpen] = useState(false);
    const [_msg, _setMsg] = useState('');

    const handleClose = () => {
        if (user) {
            httpRequest.post(paths.api.admin.adminLogout + `?UserID=${user?.id}&Token=${user?.token}`)
                .then((resp: any) => {
                    authService.removeUser();
                    navigate(paths.auth.login);
                })
                .catch((err: any) => { console.log(err) })
                .finally(() => {
                    authService.removeUser();
                    localStorage.clear()
                    navigate(paths.auth.login);
                })
        } else {
            localStorage.clear()
            navigate(paths.auth.login);
        }
    };

    useEffect(() => {
        if (!user) {
            authService.removeUser();
            navigate(paths.auth.login);
        }
        const authInterceptor = httpRequest.interceptors.request.use(function (config) {
            if (user) {
                config.headers = { 'token': user?.token };
            }
            return config;
        });
        return () => {
            httpRequest.interceptors.request.eject(authInterceptor);
        }
    }, [user])

    useEffect(() => {

        httpRequest.interceptors.response.use(function (response) {
            return response;
        }, function (error) {

            if (error.response.status === 401) {
                setTimeout(() => {
                    setOpen(true);
                    _setMsg('Invalid user session, Try to login again');
                }, 100);
            } else {
                return Promise.reject(error);
            }
        });
    }, []);

    return (<>
        <Dialog open={open} onClose={() => {
            handleClose();
            setOpen(false);
        }} disableEscapeKeyDown={true}>
            <DialogTitle className="text-danger">{_msg}</DialogTitle>
            <DialogActions className="justify-content-center pb-4">
                <Button variant={"contained"} onClick={() => {
                    handleClose();
                    setOpen(false);
                }}>Continue with login</Button>
            </DialogActions>
        </Dialog>
    </>);
}