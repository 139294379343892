import {base64} from './helperService';

const authService = {
    setUser() {
        const _tempData = {};
        return _tempData;
    },
    removeUser() {
        localStorage.removeItem('_bharat_temp');
        return null;
    },
    user() {
        const _tempData: string | null = localStorage.getItem('_bharat_temp');
        
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            ...user?.contact[0],
            id: user?.contact[0]?.id,
            useremail: user?.contact[0]?.useremail,
            token: user?.contact[0]?.token,
            username: user?.contact[0]?.username,
            userrole: user?.contact[0]?.userrole,
            usercode: user?.contact[0]?.usercode,
            userclassificationid: user?.contact[0]?.userclassificationid,
            masterCedentMaintenanceId: user?.contact[0]?.masterCedentMaintenanceId,
            mastereinsuranceMaintenanceId: user?.contact[0]?.mastereinsuranceMaintenanceId,
            bhrathReCommission:user?.contact[0]?.bhrathReCommission || 0,
            currencyValue:user?.contact[0]?.currencyValue || 0,
            division:user?.contact[0]?.division  || 0,
            currency:user?.currency || 0,
            name :user?.type || 0,
            cedantID :user?.cedantID || 0,
            countary :user?.countary || '',
        };
    },

    removeAdminUser(){
        localStorage.removeItem('_admin_temp');
        return null;
    },

    adminUser() {
        const _tempData: string | null = localStorage.getItem('_admin_temp');
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            emailAddress: user?.emailAddress,
            token: user?.token,
            firstName: user?.firstName,
            lastName: user?.lastName,
            roleid: user?.roleid,
            id: user?.id
        };
    },

    removeExecutiveUser(){
        localStorage.removeItem('_executive_temp');
        return null;
    },

    executiveUser() {
        const _tempData: string | null = localStorage.getItem('_executive_temp');
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            emailAddress: user?.emailAddress,
            token: user?.token,
            firstName: user?.firstName,
            lastName: user?.lastName,
            id: user?.id
        };
    },

    removeReinsuranceUser(){
        localStorage.removeItem('_reinsurance_temp');
        return null;
    },
    reinsuranceUser() {
        const _tempData: string | null = localStorage.getItem('_reinsurance_temp');
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            token: user?.token,
            id: user?.id,
            username: user?.username,
            usercode: user?.usercode,
            useremail: user?.useremail,
            userDetailId: user?.userDetailId,
            bhrathReCommission: user?.bhrathReCommission,
            currencyValue: user?.currencyValue,
            isActive: user?.isActive,
        };
    },

    removeCedentUser(){
        localStorage.removeItem('_cedent_temp');
        return null;
    },
    cedentUser() {
        const _tempData: string | null = localStorage.getItem('_cedent_temp');
        if (!_tempData) return null;

        const user = JSON.parse(base64.decode(_tempData));
        return {
            emailAddress: user?.emailAddress,
            token: user?.token,
            username: user?.username,
            isActive: user?.isActive,
            userDetailId: user?.userDetailId,
            usercode:user?.usercode,
            bhrathReCommission:user?.bhrathReCommission,
            currencyValue:user?.currencyValue
        };
    }
}

export {authService};