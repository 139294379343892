import { Outlet } from 'react-router-dom';

function AuthLayout() {
    return (<>
        <div className="min-vh-100">
            <div className='bg-white container p-5'>
                <div className="row align-items-center justify-content-center vh-100">
                    <div className='col-md-10'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AuthLayout;
