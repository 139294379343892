import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useState } from 'react'

export function MsgPopup({msg, changeFlag}:any) {
    const [_flag, _setFlag] = useState(true);
    const handleClose = () => {
        _setFlag(false);
        changeFlag();
    }
    return (<>
        <Dialog open={_flag} onClose={handleClose}>
            <DialogContent>
                <label>{msg}</label>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center'>
                <Button variant="contained" onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    </>
    )
}
