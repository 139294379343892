import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Logout from '../../assets/images/dashicon/Logout.png'
import { paths } from '../../configs/constants';
import AdminSideBar from './includes/AdminSideBar';
import { authService } from '../../services/authService';
import { insertAuditLog } from '../../models/model';
import { adminNavMenuList } from '../../configs/sideMenuItem';
import clsx from 'clsx';
import { useStateValue } from '../../providers/stateProvider';
import { FormatAlignLeft as IconFormatAlignLeft } from '@mui/icons-material';
import { httpRequest } from "../../services/httpService";

export default function AdminNewLayout() {
    const navigate = useNavigate();
    const [{ user }]: any = useStateValue();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(false);
    const [_message, _setMessage] = useState<any>([]);
    const [_proposalRequestResponseId, _setResponseId] = useState<any>();
    const [_request, _setRequest] = useState([]);
    const [_response, _setResponse] = useState([]);
    const [_company, _setCompany] = useState('');
    const [_category, _setCategory] = useState('');
    const [_proposalNo, _setproposalNo] = useState('');
    const [_proposalId, _setProposalId] = useState<any>();
    const [_totalCount, _setTotalCount] = useState<any>();

    const adminLogout = () => {
        const adminData = authService.user();
        let obj = {
            userid: adminData?.id,
            username: adminData?.username,
            pagename: 'Logout',
            usertype: 'Admin',
            actionperform: 'Clicked'

        }

        insertAuditLog(obj)
            .then((resp) => resp.data)
            .catch(err => console.log(err))

        httpRequest.post(paths.api.admin.adminLogout + `?UserID=${user?.id}&Token=${user?.token}`)
            .then((resp: any) => {
                authService.removeUser();
                navigate(paths.auth.login);
            })
            .catch((err: any) => {
                console.log(err);
                authService.removeUser();
                navigate(paths.auth.login);
            })
    }

    const toggleSidebarClick = () => {
        _setToggleSidebar(!_toggleSidebar);
    }

    useEffect(() => {
        _setToggleSidebar(!isDesktop);
    }, [isDesktop])

    return (<>
        <Suspense fallback={null}>
            <div className="min-vh-100">
                <div>
                    <AdminSideBar adminNavMenuList={adminNavMenuList} toggleSidebar={_toggleSidebar} />
                </div>
                <div>
                    <div className={clsx("navHeader", _toggleSidebar && 'navHeaderFull', 'd-flex justify-content-between align-items-center bg-white px-3')}>
                        <IconButton onClick={toggleSidebarClick}><IconFormatAlignLeft /></IconButton>
                        <div className='d-flex'>
                            <span className='bolder' style={{ textTransform: "capitalize", padding: "0px 7px" }}>{user?.username}</span>
                            <span className='bolder' role="button" onClick={adminLogout}>Logout<img src={Logout} style={{ width: "1.5rem" }} className="ms-3" /></span>
                        </div>
                    </div>
                    <main className={clsx('contentPage', _toggleSidebar && 'contentPageFull', 'bg-main-light px-3')}>
                        <Outlet />
                    </main>
                </div>
            </div>
        </Suspense>
    </>)
}

const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
}
