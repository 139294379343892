import React, { useState, MutableRefObject, useEffect, useRef } from "react";
import { useTheme, Badge, IconButton, Paper, Tooltip, Popover, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Avatar } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { paths } from "../../../configs/constants";
import Logout from '../../../assets/images/dashicon/Logout.png'
import { authService } from "../../../services/authService";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { httpRequest } from "../../../services/httpService";
import { useStateValue } from '../../../providers/stateProvider';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CachedIcon from '@mui/icons-material/Cached';
import CircularProgress from '@mui/material/CircularProgress';
import { MAX_FILE_SIZE, formatDateTime } from '../../../services/helperService';
import CloseIcon from '@mui/icons-material/Close';
import { MsgPopup } from "../../helpers/popups";

export default function Navbar() {
    const navigate = useNavigate();
    const theme = useTheme();
    const refDocument1 = useRef() as MutableRefObject<HTMLInputElement>;
    const [{ user }, dispatch]: any = useStateValue();
    const [_totalCount, _setTotalCount] = useState<any>();
    const [_tableLoading, _setTableLoading] = useState(true);
    const [_errorMsg, _setErrorMsg] = useState<any>();
    const [_proposalNo, _setproposalNo] = useState('');
    const [_proposalDate, _setProposalDate] = useState('');
    const [_company, _setCompany] = useState('');
    const [_category, _setCategory] = useState('');
    const [_remarks, _setremarks] = useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const [_request, _setRequest] = useState<any>([]);
    const [isOpenRes, setIsOpenRes] = React.useState(false);
    const [_response, _setResponse] = useState([]);
    const [_proposalRequestResponseId, _setResponseId] = useState<any>();
    const [_openReply, _setOpenReply] = useState<any>(false);
    const [_openReply1, _setOpenReply1] = useState<any>(false);
    const [_loadingReply, _setLoadingReply] = useState(false);
    const [_chatList, _setChatList] = useState<any>([]);
    const [_customerData, _setCustomerData] = useState<any>({});
    const [_docs, _setDocs] = useState<any>({});
    const [_proposalResponseId, _setProposalResponseId] = useState<any>(1);
    const [_documentName, _setDocumentName] = useState<any>();
    const [_documentId, _setDocumentId] = useState<any>();
    const [_proposalId, _setProposalId] = useState<any>();
    const [_selectedItem, _setSelectedItem] = useState<any>({});
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [_message, _setMessage] = useState<any>([]);
    const open = Boolean(anchorEl);
    const [anchorEl1, setAnchorEl1] = React.useState<HTMLElement | null>(null);
    const open1 = Boolean(anchorEl1);
    const [_existingPass, _setExistingPass] = useState<any>();
    const [_newPass, _setNewPass] = useState<any>();
    const [_confirmPass, _setConfirmPass] = useState<any>();
    const [_openDialog, _setOpenDialog] = useState<any>(false);
    const [_userDetailId, _setUserDetailId] = useState<any>();
    const [_executiveId, _setExecutive] = useState<any>();
    const [_msgError, _setMsgError] = useState(false);

    const validationobj = {
        errorConfirmPass: false, errorMsgConfirmPass: ''
    }
    const [_validation, _setValidation] = useState<any>([validationobj]);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOpen1 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const onUpdate = () => {
        _setOpenReply1(true);
    }
    const onUpdatePassword = () => {

        let isValid = true;
        const validations: any = Object.assign({}, validationobj);
        const data = {
            ID: user?.id,
            oldpassword: _existingPass,
            newpassword: _newPass
        }
        if (data.newpassword != _confirmPass) {
            isValid = false;
            validations.errorConfirmPass = true;
            validations.errorMsgConfirmPass = 'Confirm your correct password';
        }
        _setValidation(validations);
        if (!isValid) {
            return false;
        }
        httpRequest.post(paths.api.admin.updatePassword, data)
            .then(response => {

                if (response.data.status = "Success") {
                    _setErrorMsg("Your Password Updated Successfully !!");
                    _setOpenDialog(true);
                }

            }).catch(error => {

            })

    }
    const onClose = () => {
        _setOpenReply1(false);
    }

    const onClose1 = () => {
        _setOpenReply(false);
    }
    const handleClose2 = () => {
        _setOpenDialog(false);
        _setOpenReply1(false);
    };
    const onUpload = (e: any) => {
        if (e.target.files[0]?.size > MAX_FILE_SIZE) {
            _setMsgError(true);
            return;
        }
        e.preventDefault();
        _setDocumentName(e.target.files[0].name)
        const formData = new FormData();
        formData.append('Image', e.target.files[0]);
        formData.append('proposalResponseId', _proposalId);
        formData.append('refType', 'proposal');
        formData.append('docType', 'document');
        formData.append('documentName', e.target.files[0].name);
        httpRequest.post(paths.api.cedent.cedentDocumentUpload, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(response => {
                if (response.data.status == "Success") {
                    _setDocumentId(response.data.data);
                }
                else {
                    alert(response.data.data);
                }
            }).catch(error => {
            })
    }

    const togglePopup = (item: any) => {
        _setSelectedItem(item);
        setIsOpen(true);
        _setOpenReply(true);
        _setTableLoading(false);
        _setProposalId(item?.proposalListId);
        _setCompany(item?.company);
        _setCategory(item?.category);
        _setUserDetailId(item?.responseUserDetailId);
        _setproposalNo(item?.proposalNo);
        httpRequest.get(paths.api.reinsurance.getReinsuranceMsg + '?proposallistid=' + item?.proposalListId + '&userdetailsid=' + user?.mastereinsuranceMaintenanceId + '&userid=' + user?.id + '&ProposalListReinsuranceId=' + item?.proposalListReinsuranceId)
            .then(response => {
                debugger;
                if (response.data.status === "Success") {
                    _setRequest(response.data.data.request);
                }
            }).catch(error => {
            })
            .finally(() => _setTableLoading(false));
    };

    const onSubmitResponse = (e: any) => {
        debugger;
        let docId: any;

        if (_documentId == undefined) {
            docId = 0;
        }
        else {
            docId = _documentId;
        }
        const data = {
            userid: user?.mastereinsuranceMaintenanceId,
            proposallistid: _proposalId,
            chattype: "ReInsurance",
            chatmessage: _remarks,
            lastUpdatedBy: user?.username,
            category: _category,
            company: _company,
            proposalType: (_request?.length === 0 || _request[0]?.chattype === 'ReInsurance') ? 'Request' : 'Response',
            msgTo: 'BharathRe',
            proposalNo: _proposalNo,
            responseUserDetailId: _userDetailId,
            documentid: docId,
            proposallistReinsuranceID: _selectedItem?.proposalListReinsurancesId,
        }
        httpRequest.post(paths.api.cedent.cedentInsertChat, data)
            .then(response => {
                setIsOpenRes(!isOpenRes);
                _setremarks('');
                _setDocumentName('');
                _setDocumentId(0);
                const pData = {
                    proposalNo: _proposalNo,
                    proposalListId: _proposalId,
                    responseUserDetailId: _userDetailId,
                    category: _category,
                    company: _company,
                    proposalListReinsurancesId: _selectedItem?.proposalListReinsurancesId,

                }
                togglePopup(pData);

                httpRequest.post(paths.api.cedent.sendChatMail, { ...data, proposalType: 'Response', proposallistReinsuranceID: _selectedItem?.proposalListReinsurancesId })
                    .then((resp) => console.log(resp))
                    .catch((err) => console.log(err))
            }).catch(error => {
                if (error.response.status === 500) {
                    if (error.response.data.status === "Error") {
                    }
                }
            });
    };

    const getNotifications = () => {
        httpRequest.get(paths.api.reinsurance.getNotification + '?userdetailsid=' + user?.id + '&MastereinsuranceMaintenanceId=' + user?.mastereinsuranceMaintenanceId)
            .then(response => {
                _setTotalCount(response?.data?.data?.request?.length);
                _setMessage(response?.data?.data?.request);
            })
    }
    const onNotificationClick = (item: any) => {

        httpRequest.put(paths.api.cedent.cedentNotificationRead + '?proposalRequestResponseId=' + item?.proposalRequestResponseId + '&markread=1')
            .then(response => {
                getNotifications();
            });
        togglePopup(item);
    };
    useEffect(() => {
        getNotifications();
    }, []);
    const reinsuranceLogout = () => {
        httpRequest.post(paths.api.admin.adminLogout + `?UserID=${user?.id}&Token=${user?.token}`)
            .then((resp: any) => {
                authService.removeUser();
                navigate(paths.auth.login);
                localStorage.removeItem('proposal1_info');
            })
            .catch((err: any) => {
                console.log(err)
                authService.removeUser();
                navigate(paths.auth.login);
            })
    };

    return (

        <div className="">
            <div className="w-100 d-flex align-items-center justify-content-end text-center flex-wrap gap-3">
                <div aria-describedby={open ? 'simple-popover' : undefined} onClick={handleOpen} role="button">
                    <Badge badgeContent={_totalCount} color="primary" className='mx-3'>
                        <NotificationsIcon className='text-dark' />
                    </Badge>
                </div>
                <div aria-describedby={open1 ? 'simple-popover' : undefined} onClick={handleOpen1}>
                    <span className='bolder' style={{ textTransform: "capitalize" }}>{user?.username}</span>
                </div>
                <span className='bolder' role="button" onClick={reinsuranceLogout}>Logout<img src={Logout} style={{ width: "1.5rem" }} className="ms-3" /></span>
            </div>
            <Popover id={open ? 'simple-popover' : undefined} onClose={handleClose} open={open} anchorEl={anchorEl} style={{ overflowX: "auto" }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className="p-2" style={{ minWidth: 300, maxWidth: 350 }}>
                    <Link to={paths.reinsurance.ProposalListing} className="text-decoration-none"><span className='text-info' onClick={handleClose}>Ticket Notification</span></Link>
                    <hr className="mt-2" />
                    {_message?.length > 0 ?
                        _message?.map((item: any, index: number) => {
                            return <div key={index} className="d-flex justify-content-between gap-2">
                                <small className="fw-bold" role="button" onClick={(e: any) => onNotificationClick(item)}>{item?.proposalNo || '-'}</small>
                                <small style={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item?.remark}</small>
                            </div>
                        }) : <div className="text-center"><small className="text-muted">(Empty)</small></div>}
                    <hr />
                </div>
            </Popover>
            <Popover id={open1 ? 'simple-popover' : undefined} onClose={handleClose1} open={open1} anchorEl={anchorEl1} style={{ overflowX: "auto" }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className="p-2" style={{ minWidth: 200 }}>
                    <label onClick={onUpdate}>Update Your Password</label>
                </div>

            </Popover>
            <Dialog maxWidth="md" fullWidth open={_openReply} onClose={onClose1} scroll="body">
                <DialogTitle className="bg-light-1 d-flex justify-content-between align-items-center">
                    <div></div>
                    <div>Reply On Requests <Tooltip title="Reload"><CachedIcon role="button" /></Tooltip></div>
                    <div><IconButton aria-label="add" onClick={onClose1}><CloseIcon /></IconButton></div>
                </DialogTitle>
                <DialogContent className="bg-light-1 ">
                    <div className="d-flex justify-content-center gap-2">
                        <div>Proposal No: <span className="fw-bold">{_selectedItem?.proposalNo}</span></div>
                        <div>ReInsurer Name: <span className="fw-bold">{user?.username}</span></div>
                    </div>
                    <div className='col-md-12 px-5 mt-4'>
                        <div className='row mt-4'>
                            <div className='col-1'><Avatar sx={{ bgcolor: "#9400ff" }}>R</Avatar></div>
                            <div className='col-11'>
                                <Paper className="px-3">
                                    <TextField className="mt-2" fullWidth placeholder="Write a comments" multiline variant='standard' minRows={2} InputProps={{ disableUnderline: true }}
                                        onChange={(e) => _setremarks(e.target.value)} value={_remarks} />
                                    <div className="d-flex justify-content-between">
                                        <LoadingButton className="fw-bold mb-3" endIcon={<></>} variant="contained" loadingPosition="end" loading={_loadingReply}
                                            size="large" onClick={(e: any) => onSubmitResponse(e)} sx={{ minWidth: "150px" }}>SEND</LoadingButton>
                                        <Button className="fw-bold fs-6 p-0" component="label">
                                            <span className="muted">{_documentName || 'Attachment'}</span>
                                            <AttachFileIcon />
                                            <input type="file" ref={refDocument1} onChange={(e: any) => onUpload(e)} hidden />
                                        </Button>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                        {_tableLoading ?
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '10rem' }}>
                                <CircularProgress color="secondary" />
                            </div> :
                            _request?.map((item: any, index: number) => <div className='row mt-3' key={index}>
                                <div className='col-1'>
                                    <Avatar sx={{ bgcolor: item?.chattype === "BharathRe" ? "#ff5722" : item?.chattype === "ReInsurance" ? "#9400ff" : "#007fff" }}>{item?.chattype === "BharathRe" ? "E" : item?.chattype === "ReInsurance" ? "R" : "C"}</Avatar>
                                </div>
                                <div className='col-11'>
                                    <div className="d-flex">
                                        <span className='fw-bold'>{item?.chattype}</span><small className='text-muted'>&nbsp;{formatDateTime(item?.chattime)} </small>
                                    </div>
                                    <Paper className={clsx(item?.chattype === "ReInsurance" && !item?.isRead && 'border bg-info bg-opacity-10', 'px-3')}>
                                        {index === _request?.length - 1 ? <div className="py-3" dangerouslySetInnerHTML={{ __html: item?.chatmessage }} />
                                            : <div className="py-3">{item?.chatmessage}</div>}
                                        {item.documentList[0] && <div className="">
                                            <span className="text-muted">Attached Document</span>
                                            <Button className="mx-2 mb-1 py-0 fw-bold" variant="outlined" size="small" href={paths.api.cedent.documentDownload + '?documentId=' + item?.documentList[0]?.documentId} target="_self">View</Button>
                                        </div>}
                                    </Paper>
                                </div>
                            </div>)}
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" open={_openReply1} onClose={onClose} scroll="body">
                <DialogTitle className="bg-light-1 d-flex justify-content-between align-items-center">
                    Update Your Password here
                    <div><IconButton aria-label="add" onClick={onClose}><CloseIcon /></IconButton></div>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex p-2">
                        <div className="col-md-6 d-flex vertAlign">
                            <label>Existing Password</label>
                        </div>
                        <div className="col-md-6">
                            <TextField label="Enter your Existing password" value={_existingPass} onChange={(e: any) => _setExistingPass(e.target.value)} className='rounded mt-3' variant="outlined" type={'password'} fullWidth />
                        </div>
                    </div>
                    <div className="d-flex p-2">
                        <div className="col-md-6 d-flex vertAlign">
                            <label>New Password</label>
                        </div>
                        <div className="col-md-6">
                            <TextField label="Enter your password" value={_newPass} onChange={(e: any) => _setNewPass(e.target.value)} className='rounded mt-3' variant="outlined" type={'password'} fullWidth />
                        </div>
                    </div>
                    <div className="d-flex p-2">
                        <div className="col-md-6 d-flex vertAlign">
                            <label>Confirm Password</label>
                        </div>
                        <div className="col-md-6">
                            <TextField label="Enter your password" className={clsx(_validation.errorConfirmPass && 'is-invalid')} value={_confirmPass} onChange={(e: any) => _setConfirmPass(e.target.value)} variant="outlined" type={'password'} fullWidth />
                            {_validation.errorConfirmPass && <div className="invalid-feedback">{_validation.errorMsgConfirmPass}</div>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button variant="contained" style={{ borderRadius: "5px" }} onClick={onUpdatePassword}>OK</Button>

                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={_openDialog} onClose={handleClose2}>
                <DialogContent>
                    <label>{_errorMsg}</label>
                </DialogContent>
                <DialogActions className='d-flex justify-content-center'>
                    <Button variant="contained" onClick={handleClose2}>OK</Button>
                </DialogActions>
            </Dialog>
            {_msgError && <MsgPopup msg={'File size exceeds the allowed limit (less than 10MB).'} changeFlag={() => _setMsgError(false)} />}
        </div>
    );
}