import * as React from "react";
import { httpRequest } from "../services/httpService";
import { paths } from "../configs/constants";
export interface PropRoute {
    path: string;
    element?: React.ReactNode | null;
}
// const[paramConfigs,_setparamconfig] =React.useState([]);
export interface propsCountry {
    id: number;
    countryName: string;
    countryCode: string;
    countryAbbr: string;
    iconURL: string;
}

// export const countryList = () => httpRequest.get<propsCountry[]>();
// All login
export const getAllLogin = (formData: any) => {
    const url = `${paths.api.admin.allLogin}?email=${formData.email}&password=${formData.password}`
    return httpRequest.get(url);
};

// Admin API's ....

export const getCedentDetail = () => {
    return httpRequest.get(paths.api.admin.cedentDetail);
}

export const updateproposalno = (formData: any) => {
    debugger;
    const paramConfigs = {
        CedentID: formData.id,
        ProposalCloseDays: formData.closurePeriod,
        resetProposalNo: formData.resetProposalNo
    }

    return httpRequest.post(paths.api.admin.updateproposalno, formData);
}

export const getAllCountry = () => {
    return httpRequest.get(paths.api.admin.getAllCountry);
};

export const insertCountry = (formData: any) => {
    const data = {
        CountryName: formData?.countryName,
        CountryCode: '1',
        Currency: formData?.currency,
        ObligativePercentage: formData?.obligatory,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        SmtpDomainName: formData?.smtpDomainName || '',
        Smtpport: Number(formData?.smtpport || '0') || 0,
        Smtpuseremail: formData?.smtpuseremail || '',
        Smtppassword: formData?.smtppassword || '',
    };
    return httpRequest.post(paths.api.admin.insertCountry, data);
};

export const updateCountry = (formData: any) => {
    const data = {
        Id: formData?.id,
        CountryName: formData?.countryName,
        CountryCode: '1',
        Currency: formData?.currency,
        ObligativePercentage: formData?.obligatory,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        SmtpDomainName: formData?.smtpDomainName || '',
        Smtpport: formData?.smtpport || 0,
        Smtpuseremail: formData?.smtpuseremail || '',
        Smtppassword: formData?.smtppassword || '',
    };
    return httpRequest.post(paths.api.admin.insertCountry, data);
};

export const getSendTestMailSmtp = (formData: string) => {
    return httpRequest.get(paths.api.admin.SendTestMailSmtp + formData)
}

export const getOccupancy = () => {
    return httpRequest.get(paths.api.admin.getOccupancy);
};

export const insertOccupancy = (formData: any) => {
    const data = {
        Occupancy: formData?.occupancy,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        ProductList: formData?.ProductList
    };
    return httpRequest.post(paths.api.admin.insertOccupancy, data);
};

export const updateOccupancy = (formData: any) => {
    const data = {
        Id: formData?.id,
        Occupancy: formData?.occupancy,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        ProductList: formData?.ProductList

    };
    return httpRequest.put(paths.api.admin.updateOccupancy, data);
};

export const getIndustry = () => {
    return httpRequest.get(paths.api.admin.getIndustry);
};
export const getProposalTypeList = () => {
    return httpRequest.get(paths.api.admin.proposalTypeList);
};

export const insertIndustry = (formData: any) => {
    const data = {
        typeName: formData?.typeName?.trim(),
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertIndustry, data);
};

export const updateIndustry = (formData: any) => {
    const data = {
        Id: formData?.id,
        typeName: formData?.typeName?.trim(),
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateIndustry, data);
};

export const getCompanyRole = () => {
    return httpRequest.get(paths.api.admin.getCompanyRole);
};

export const insertCompanyRole = (formData: any) => {
    const data = {
        RoleName: formData?.roleName,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertCompanyRole, data);
};

export const updateCompanyRole = (formData: any) => {
    const data = {
        Id: formData?.id,
        RoleName: formData?.roleName,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateCompanyRole, data);
};

export const getProposalType = () => {
    return httpRequest.get(paths.api.admin.getProposalType);
};

export const insertProposalType = (formData: any) => {
    const data = {
        typeName: formData?.typeName?.trim(),
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertProposalType, data);
};

export const updateProposalType = (formData: any) => {
    const data = {
        Id: formData?.id,
        typeName: formData?.typeName?.trim(),
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateProposalType, data);
};

export const getRiskClassification = () => {
    return httpRequest.get(paths.api.admin.getRiskClassification);
};
export const getAllRiskClassification = () => {
    return httpRequest.get(paths.api.admin.getAllRiskClassification);
};

export const insertRiskClassification = (formData: any) => {
    const data = {
        risktype: formData?.risktype,
        ProposalTypeId: formData?.proposalType,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertRiskClassification, data);
};

export const updateRiskClassification = (formData: any) => {
    const data = {
        riskid: formData?.riskid,
        risktype: formData?.risktype,
        ProposalTypeId: formData?.proposalType,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateRiskClassification, data);
};

export const getUserClassification = () => {
    return httpRequest.get(paths.api.admin.getUserClassification);
};

export const insertUserClassification = (formData: any) => {
    const data = {
        userclassification: formData?.userclassification,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertUserClassification, data);
};
export const insertorupdateaddoncover = (formData: any) => {

    return httpRequest.post(paths.api.admin.insertaddoncover, formData);
};
export const insertorunderwriting = (formData: any) => {

    return httpRequest.post(paths.api.admin.insertunderwriting, formData);
};

export const getaddoncoverdata = () => {
    return httpRequest.get(paths.api.admin.GetAddOnCover);
};
export const getAddOnCoverByProposalType = (id: any) => {
    return httpRequest.get(paths.api.admin.GetAddOnCoverByProposalType + `?proposaltypeId=${id}`);
};
export const getunderwriting = () => {
    return httpRequest.get(paths.api.admin.Getunderwriting);
};
export const getUnderwritingByProposalType = (id: any) => {
    return httpRequest.get(paths.api.admin.GetUnderwritingByProposalType + `?proposaltypeId=${id}`);
};
export const updateUserClassification = (formData: any) => {
    debugger;
    const data = {
        id: formData?.id,
        userclassification: formData?.userclassification1,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateUserClassification, data);
};

export const getReBranch = () => {
    return httpRequest.get(paths.api.admin.getAllReBranch);
};

export const insertReBranch = (formData: any) => {
    debugger;

    const data = {
        Countryid: formData?.counteryid,
        branchname: formData?.branchname,
        branchid: 0,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        EmailIdCc1: formData.emailIdCc1,
        EmailIdCc2: formData.emailIdCc2,
        EmailIdCc3: formData.emailIdCc3,
        EmailIdCc4: formData.emailIdCc4,
        EmailIdCc5: formData.emailIdCc5,
        internationalCommission: String(formData.internationalCommission),
        domesticCommission: String(formData.domesticCommission),
        complianceCommission: String(formData.complianceCommission),
        bharatreCommission: String(formData.bharatreCommission),
        operatingcountries: formData.operatingcountries,

    };
    return httpRequest.post(paths.api.admin.insertReBranch, data);
};


export const updateReBranch = (formData: any) => {
    const data = {
        branchid: formData?.branchid,
        Countryid: formData?.counteryid,
        branchname: formData?.branchname,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        EmailIdCc1: formData.emailIdCc1,
        EmailIdCc2: formData.emailIdCc2,
        EmailIdCc3: formData.emailIdCc3,
        EmailIdCc4: formData.emailIdCc4,
        EmailIdCc5: formData.emailIdCc5,
        internationalCommission: String(formData.internationalCommission),
        domesticCommission: String(formData.domesticCommission),
        bharatreCommission: String(formData.bharatreCommission),
        complianceCommission: String(formData.complianceCommission),
        operatingcountries: formData.operatingcountries,

    };
    debugger;
    return httpRequest.put(paths.api.admin.updateReBranch, data);
};

export const getCedentMaintainence = () => {
    return httpRequest.get(paths.api.admin.getCedentMaintainence);
};

export const insertCedentMaintainence = (formData: any) => {
    const data = {
        id: formData?.id,
        cedentname: formData?.cedentname,
        cedentabvr: formData?.cedentabvr,
        branchid: formData?.branchid,
        email: formData?.email,
        contactperson: formData?.contactperson,
        division: formData?.division,
        address: formData?.address,
        landlineno: formData?.landlineno,
        mobileno: formData?.mobileno,
        countaryid: formData?.countaryid,
        bhrathReCommission: formData?.bhrathReCommission,
        proposalno: formData?.proposalno,
        EmailList: formData?.emailList,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertCedentMaintainence, data);
};

export const updateCedentMaintainence = (formData: any) => {
    const data = {
        id: formData?.id,
        cedentname: formData?.cedentname,
        cedentabvr: formData?.cedentabvr,
        branchid: formData?.branchid,
        email: formData?.email,
        contactperson: formData?.contactperson,
        division: formData?.division,
        address: formData?.address,
        landlineno: formData?.landlineno,
        mobileno: formData?.mobileno,
        countaryid: formData?.countaryid,
        bhrathReCommission: formData?.bhrathReCommission,
        proposalno: formData?.proposalno,
        EmailList: formData?.emailList,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateCedentMaintainence, data);
};

export const deleteEmailCedent = (id: any) => {
    return httpRequest.post(paths.api.admin.deleteEmailCedentMain + '?emailID=' + id);
};

export const getReinsuranceMaintainence = () => {
    return httpRequest.get(paths.api.admin.getReinsuranceMaintainence);
};

export const insertReinsuranceMaintainence = (formData: any) => {
    debugger;
    const data = {
        id: 0,
        adminId: formData?.adminId,
        reinsid: formData?.reinsid,
        reinsurancename: formData?.reinsurancename,
        branchid: formData?.branchid,
        emailnames: formData?.emailnames,
        contactperson: formData?.contactperson,
        division: formData?.division,
        address: formData?.address,
        landlineno: formData?.landlineno,
        mobileno: formData?.mobileno,
        countaryid: formData?.countaryid,

        SecurityAgency: formData?.securityAgency,
        SecurityRating: formData?.securityRating,
        ApprovalNo: formData?.approvalNo,
        operatingRisk: formData?.operatingRisk || "",
        proposalnotification: formData?.proposalnotification,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        riskClassificationID: formData?.riskClassificationId || 0,
        Compliance: formData?.reinsureType === "Compliance + Overseas" ? 1 : formData?.reinsureType === "Compliance" ? 1 : 0,
        Overseas: formData?.reinsureType === "Compliance + Overseas" ? 1 : formData?.reinsureType === "Overseas" ? 1 : 0,
        DomesticMarketOnly: formData?.reinsureType === "Domestic Market Only" ? 1 : 0,
    };
    return httpRequest.post(paths.api.admin.insertReinsuranceMaintainence, data);
};

export const updateReinsuranceMaintainence = (formData: any) => {
    debugger;
    const data = {
        id: formData?.id,
        reinsid: formData?.reinsid,
        reinsurancename: formData?.reinsurancename,
        branchid: formData?.branchid,
        adminId: formData?.adminId,
        emailnames: formData?.emailnames,
        contactperson: formData?.contactperson,
        division: formData?.division,
        address: formData?.address,
        landlineno: formData?.landlineno,
        mobileno: formData?.mobileno,
        countaryid: formData?.countaryid,
        proposalnotification: formData?.proposalnotification,
        IsActive: formData?.isActive,
        SecurityAgency: formData?.securityAgency,
        SecurityRating: formData?.securityRating,
        ApprovalNo: formData?.approvalNo,
        LastUpdatedBy: formData?.adminId,
        riskClassificationID: formData?.riskClassificationId || 0,
        operatingRisk: formData?.operatingRisk || "",
        Compliance: formData?.reinsureType === "Compliance + Overseas" ? 1 : formData?.reinsureType === "Compliance" ? 1 : 0,
        Overseas: formData?.reinsureType === "Compliance + Overseas" ? 1 : formData?.reinsureType === "Overseas" ? 1 : 0,
        DomesticMarketOnly: formData?.reinsureType === "Domestic Market Only" ? 1 : 0,
    };
    return httpRequest.put(paths.api.admin.updateReinsuranceMaintainence, data);
};

export const deleteReinsuranceMaintainence = (id: any) => {
    return httpRequest.post(paths.api.admin.deleteReinsuranceMaintainence + '?ReinsurerID=' + id);
}

export const getparameterconfig = () => {
    return httpRequest.post(paths.api.admin.getparameterconfig);
}
export const updateparameterconfiguration = (formData: any) => {
    // const data = {
    //     financialyear:formData?.financialyear,
    // };
    return httpRequest.post(paths.api.admin.updateparameterconfiguration + "?FinancialYear=" + formData?.financialYear + '&showAll=' + (formData?.showAllReinsurer ? 1 : 0))
};
export const getCedentConfig = () => {
    return httpRequest.get(paths.api.admin.getCedentConfig);
};
export const getRejectReason = () => {
    return httpRequest.get(paths.api.admin.getRejectReason);
};

export const insertRejectReason = (formData: any) => {
    const data = {
        reasonid: 0,
        reasonname: formData?.reasonname,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertRejectReason, data);
};

export const updateRejectReason = (formData: any) => {
    const data = {
        reasonid: formData?.reasonid,
        reasonname: formData?.reasonname,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateRejectReason, data);
};

export const getUserRole = () => {
    return httpRequest.get(paths.api.admin.getUserRole);
};

export const insertUserRole = (formData: any) => {
    const data = {
        roleid: 0,
        role: formData?.role,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.post(paths.api.admin.insertUserRole, data);
};

export const updateUserRole = (formData: any) => {
    const data = {
        roleid: formData.roleid,
        role: formData?.role,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
    };
    return httpRequest.put(paths.api.admin.updateUserRole, data);
};

export const getUserDetails = () => {
    return httpRequest.get(paths.api.admin.getUserDetails);
};

export const insertUserDetails = (formData: any) => {
    debugger;
    const data = {
        id: 0,
        usercode: formData?.usercode,
        username: formData?.username,
        useremail: formData?.useremail?.trim(" "),
        password: formData?.password,
        userrole: formData?.userrole,
        mobileno: formData?.mobileno,
        userclassificationid: formData?.userclassificationid,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        masterCedentMaintenanceId: formData?.masterCedentMaintenanceId || 0,
        mastereinsuranceMaintenanceId: formData?.mastereinsuranceMaintenanceId || 0,
        MasterBranchId: formData?.branchid || 0

    };
    return httpRequest.post(paths.api.admin.insertUserDetails, data);
};

export const updateUserDetails = (formData: any) => {
    const data = {
        id: formData?.id,
        usercode: formData?.usercode,
        username: formData?.username,
        useremail: formData?.useremail?.trim(" "),
        password: formData?.password,
        userrole: formData?.userrole,
        mobileno: formData?.mobileno,
        userclassificationid: formData?.userclassificationid,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId,
        masterCedentMaintenanceId: formData?.masterCedentMaintenanceId || 0,
        mastereinsuranceMaintenanceId: formData?.mastereinsuranceMaintenanceId || 0,
        MasterBranchId: formData?.branchid
    };
    return httpRequest.put(paths.api.admin.updateUserDetails, data);
};

export const getAuditLog = () => {
    return httpRequest.get(paths.api.admin.getAuditLog);
}

export const insertAuditLog = (formData: any) => {
    const data = {
        id: 0,
        userid: formData?.userid,
        username: formData?.username,
        pagename: formData?.pagename,
        usertype: formData?.usertype,
        actionperform: formData?.actionperform
    };
    return httpRequest.post(paths.api.admin.insertAuditLog, data);
};

export const getAllUserAccessPage = () => {
    return httpRequest.get(paths.api.admin.getAllUserAccess);
}

export const getAllUserAccessPageById = (formData: any) => {
    return httpRequest.get(paths.api.admin.getUserAccessById + `?id=${formData?.id}`);
}

export const insertUserAccessPage = (formData: any) => {
    const data = {
        id: 0,
        userroleid: formData?.userroleid,
        mainname: formData?.mainname,
        pagename: formData?.pagename,
        displayname: formData?.displayname,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId
    };
    return httpRequest.post(paths.api.admin.insertUserAccess, data);
};

export const updateReinsureEmail = (formData: any) => {
    return httpRequest.put(paths.api.admin.reinsureUpdateMultiEmail, formData);
};
export const insertReinsureEmail = (formData: any) => {
    return httpRequest.post(paths.api.admin.reinsureInsertMultiEmail, formData);
};
export const getReinsureEmail = (formData: any) => {
    return httpRequest.get(paths.api.admin.reinsureGetMultiEmail + '?reinsid=' + formData?.id);
};

export const updateUserAccess = (formData: any) => {
    const data = {
        id: formData?.id,
        userroleid: formData?.userroleid,
        mainname: formData?.mainname,
        pagename: formData?.pagename,
        displayname: formData?.displayname,
        IsActive: formData?.isActive,
        LastUpdatedBy: formData?.adminId
    };
    return httpRequest.put(paths.api.admin.updateUserAccess, data);
};

export const getCedentId = () => {
    return httpRequest.get(paths.api.admin.getCedentLastId);

}

export const getAllMailsForAdmin = () => {
    return httpRequest.get(paths.api.admin.getAllEmails);
};

// Re-Insurance API's



export const getReinsuranceDashboard = (formData: any) => {
    const url = `${paths.api.reinsurance.reinsureDashboard}?fdate=${formData.fromDate}&tdate=${formData.toDate}`
    return httpRequest.get(url);
};

export const getProposalList = (formData: any) => {
    const url = `${paths.api.reinsurance.getProposalList}?UserDetailsId=${formData.id}&PageSize=${formData?.rowsPerPage}&PageNumber=${formData?.page}`
    return httpRequest.get(url);
};

export const getHistoryProposalList = () => {
    return httpRequest.get(paths.api.reinsurance.getHistoryProposal);
};

export const getAcceptProposal = (formData: any) => {
    const url = `${paths.api.reinsurance.getAcceptProposal}?ProposalListReinsurancesId=${formData.id}&AcceptedPercentage=${formData.percentage}&Remark=${formData.remark}`
    return httpRequest.get(url);
};
export const sendAcceptMail = (formData: any) => {
    const url = `${paths.api.reinsurance.sendAcceptMail}?ProposalListReinsurancesId=${formData.id}&AcceptedPercentage=${formData.percentage}&Remark=${formData.remark}`
    return httpRequest.get(url);
};

export const getRejectProposal = (formData: any) => {
    const url = `${paths.api.reinsurance.getRejectProposal}?ProposalListReinsurancesId=${formData.id}&Remark=${formData.remark}`
    return httpRequest.get(url);
};

// Cedent API's


export const getCedentRole = () => {
    return httpRequest.get(paths.api.cedent.cedentRole);
}
export const getCedentCountry = () => {
    return httpRequest.get(paths.api.cedent.cedentCountry);
}
export const getCedentIndustries = () => {
    return httpRequest.get(paths.api.cedent.cedentIndustries);
}
export const getDeActiveProposalListing = (formData: any) => {
    const url = `${paths.api.cedent.cedentHistory}?userDetailId=${formData.id}&isActive=${formData.active}&`
    return httpRequest.get(url);
}

export const getProposalListingById = (formData: any) => {
    const url = `${paths.api.cedent.cedentProposalListing}?userDetailId=${formData.id}`
    return httpRequest.get(url);
};



export const getOccupancyByRiskId = (id: any) => {
    return httpRequest.get(paths.api.cedent.occupancyByRiskId + `?riskID=${id}`);
}
export const getCedentOccupany = () => {
    return httpRequest.get(paths.api.cedent.cedentOccupancy);
}
export const getCedentProposalType = () => {
    return httpRequest.get(paths.api.cedent.cedentProposalType);
}
export const getCedentProposalHistory = (formData: any) => {
    const url = `${paths.api.cedent.cedentHistory}?userDetailId=${formData.id}`
    return httpRequest.get(url);
}
export const getAddoncoverdata = () => {
    return httpRequest.get(paths.api.cedent.getAddoncover);
}
export const getUnderWritingList = () => {
    return httpRequest.get(paths.api.cedent.getUnderWritingClause);
}
export const getAllCountryObligative = () => {
    return httpRequest.get(paths.api.cedent.getAllCountryObligative);
}
// export const getAddoncoverdata=(formData: any)=>{
//     const url = `${paths.api.cedent.getAddoncover}?ProposalId=${formData.id}`
//     return httpRequest.get(url);
// }



export const getCedentRequestResponse = (formData: any) => {
    const url = `${paths.api.cedent.cedentRequestResponse}?ProposalId=${formData.id}`
    return httpRequest.get(url);
}
export const uploadCedentDocument = (formData: any) => {
    const data = {
        Image: formData?.image,
        proposalResponseId: formData?.responseId,
        reftype: "proposal",
        doctype: "document",
        documentName: formData?.documentName
    }

}
export const getLatestProposalId = () => {
    return httpRequest.get(paths.api.cedent.getLatestId);
}


export const getMarineFacilities = () => {
    return httpRequest.get(paths.api.cedent.getAllmarinefacilities);
}
export const getLandFacilities = () => {
    return httpRequest.get(paths.api.cedent.GetLandBasedFacilities);
}
export const getmarineOtherService = () => {
    return httpRequest.get(paths.api.cedent.GetOtherService);
}
export const getMarineAgreedWithAnyContractors = () => {
    return httpRequest.get(paths.api.cedent.getMarineAgreedWithAnyContractors);
}
export const getMarineLiabilityPortThroughputRevenue = () => {
    return httpRequest.get(paths.api.cedent.getMarineLiabilityPortThroughputRevenue);
}

// Executive API's

export const getAllProposals = (formData: any) => {
    return httpRequest.get(paths.api.executive.getAllProposals + `?PageSize=${formData?.rowsPerPage}&PageNumber=${formData?.page}`);
};

export const getExecutives = () => {
    return httpRequest.get(paths.api.executive.getExecutiveDropDown);
};

export const getReInsurance = () => {
    return httpRequest.get(paths.api.executive.getReinsuranceDropDown);
};
export const getExecutiveHistory = () => {
    return httpRequest.get(paths.api.executive.ExecutiveHitory);
}

